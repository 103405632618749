import React from "react";

const ProductsListSkeleton = () => {
  return (
    <div className="p-10    max-w-[1280px] m-auto">
      {/* Banner Skeleton */}
      <div className="w-full h-[200px] sm:h-[500px] bg-gray-200 animate-pulse rounded-md mb-6"></div>

      <div className="text-xl font-bold mb-4 animate-pulse bg-gray-300 h-6 w-36 sm:w-60 rounded"></div>

      {/* Product List Skeleton */}
      <div className="flex flex-wrap gap-4 justify-between">
        {[...Array(20)].map((_, index) => (
          <div
            key={index}
            className="animate-pulse  bg-gray-200 rounded-lg w-32 sm:w-44 md:w-48 flex flex-col"
          >
            <div className="w-full h-40 bg-gray-300 rounded-md"></div>
            <div className="mt-4 h-4 bg-gray-300 rounded w-3/4"></div>
            <div className="mt-2 h-4 bg-gray-300 rounded w-1/2"></div>
            <div className="mt-3 flex justify-between">
              <div className="h-4 bg-gray-300 rounded w-1/3"></div>
              <div className="h-4 bg-gray-300 rounded w-1/4"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductsListSkeleton;
