import React from "react";
import "./Item.css";
import { Link } from "react-router-dom";
import StarRating from "../StarRating/StarRating";

const Item = (props) => {
  return (
    <div className="item">
      <Link to={`/product/${props.id}`}>
        <img
          onClick={() => window.scrollTo(0, 0)}
          src={props.image}
          alt={props.name}
          loading="lazy"
        />
        <p className="item-text">{props.name}</p>
      </Link>
      <div className="item-rating">
        <StarRating rating={parseInt(props.rating, 10)} showLabel={false} />
      </div>
      <div className="item-prices">
        <div className="item-price-new"> ${props.new_price}</div>
        <div className="item-price-old">${props.old_price}</div>
      </div>
    </div>
  );
};

export default Item;
