import React from "react";
import kraafty from "../Assets/kraafty.png";
import "./Footer2.css";
import background from "../Assets/rajasthan4.webp";
import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  XOutlined,
  YoutubeOutlined,
  // WhatsAppOutlined,
  AmazonOutlined,
  PinterestOutlined,
} from "@ant-design/icons";

const Footer2 = () => {
  return (
    <footer
      className="relative text-white bg-cover bg-center py-10 md:py-16 overflow-hidden"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${background})`,
      }}
    >
      <div className="max-w-7xl mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 footer-columns">
        {/* Brand Details */}
        <div className="flex flex-col items-center md:items-start text-center">
          <img
            src={kraafty}
            alt="Brand Logo"
            className="w-40 md:w-48 mx-auto my-2 rounded-md"
          />
          <p className="text-center md:text-left text-sm">
            At Kraafty, we believe in the power of sustainable, eco-friendly practices.
          </p>
        </div>

        {/* Policy Links */}
        <div className="footer-policy  text-center">
          <h3 className="text-lg sm:text-xl font-semibold mb-4">POLICY</h3>
          <ul className=" w-full p-0 m-0 text-sm list-none ">
            <li>
              <a
                href="/privacy"
                className="text-white no-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="/refund"
                className="text-white no-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Refund Policy
              </a>
            </li>
            <li>
              <a
                href="/shipping"
                className="text-white no-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Shipping Policy
              </a>
            </li>
            <li>
              <a
                href="/terms-and-conditions"
                className="text-white no-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </li>
          </ul>
        </div>

        {/* Main Menu Links */}
        <div className="footer-menu   text-center">
          <h3 className="text-lg sm:text-xl font-semibold mb-4">MAIN MENU</h3>
          <ul className="list-none  w-full p-0 m-0 text-sm  ">
            <li>
              <a href="/" className="text-white no-underline">
                Home
              </a>
            </li>
            <li>
              <a href="/collections" className="text-white no-underline">
                Collections
              </a>
            </li>
            <li>
              <a href="/best-sellers" className="text-white no-underline">
                Best Sellers
              </a>
            </li>
            <li>
              <a href="/our-story" className="text-white no-underline">
                Our Story
              </a>
            </li>
            <li>
              <a href="/about-us" className="text-white no-underline">
                About Us
              </a>
            </li>
          </ul>
        </div>

        {/* Showroom Contact Details */}
        <div className="footer-contact  text-center">
          <h3 className="text-lg sm:text-xl font-semibold  mb-4">Factory</h3>
          <ul className="space-y-2 list-none  w-full p-0 m-0 text-sm ">
            <li className="text-white">Monday – Sunday: 9:00 am – 9:00 pm</li>
            <li className="text-white">
              C-11 Raghunath Colony, Galta Gate, Jaipur, Rajasthan, 302003
            </li>
            <li className="text-white">📞 +91 8005629558, +91 9468599356 </li>
            <li className="text-white">✉️ support@kraafty.com</li>
            <li className="text-white">🌐 kraafty.com</li>
          </ul>
        </div>
      </div>

      {/* Divider Line */}
      <div className="w-full h-px bg-white my-6"></div>

      {/* Social Media Icons */}
      <div className="footer-social-icons">
        {[
          { Icon: InstagramOutlined, link: "https://www.instagram.com/kraaftyindia/?hl=en" },
          { Icon: YoutubeOutlined, link: "https://www.youtube.com/@garvtambi5786/" },
          { Icon: LinkedinOutlined, link: "https://www.linkedin.com/in/jaipur-masale-a176721a8/" },
          { Icon: FacebookOutlined, link: "https://www.facebook.com/profile.php?id=61570955737998" },
          { Icon: XOutlined, link: "https://x.com/kraaftyindia" },
          { Icon: AmazonOutlined, link: "https://www.amazon.com/stores/page/AB692C05-E1B3-48A7-98A4-E852B4D1D04C" },
          { Icon: PinterestOutlined, link: "https://www.pinterest.com/kraaftyindia/" }
        ].map(({ Icon, link }, index) => (
          <div key={index} className="footer-icons-containers">
            <a href={link} target="_blank" rel="noopener noreferrer">
              <Icon style={{ fontSize: 25 }} />
            </a>
          </div>
        ))}
      </div>

      {/* Copyright Section */}
      <div className="text-center text-sm text-white mt-3">
        Copyright @ 2024 - All Right Reserved.
      </div>
    </footer>
  );
};

export default Footer2;
