import React from "react";

const NavCategorySkeleton = () => {
  return (
    <div className="flex gap-3">
      {[...Array(3)].map((_, index) => (
        <div
          key={index}
          className="w-48 sm:max-w-[170px] h-10 sm:h-11 rounded-full bg-gray-200 animate-pulse"
        ></div>
      ))}
    </div>
  );
};

export default NavCategorySkeleton;
