import React from "react";

const SearchSkeleton = () => {
  return (
    <div className="flex flex-wrap justify-between gap-4 sm:gap-2  p-2 sm:p-20 max-w-[1400px] m-auto ">
      {[...Array(12)].map((_, index) => (
        <div
          key={index}
          className="flex flex-col  p-2 sm:p-4 justify-between  w-40 sm:w-52 md:w-64 h-[300px] sm:h-[400px]  m-auto sm:m-4 rounded-lg border-2  shadow-md animate-pulse bg-white"
        >
          <div className="w-full h-40 bg-gray-400 rounded"></div>
          <div className="mt-3 h-4 bg-gray-300 w-3/4 rounded"></div>
          <div className="mt-2 h-4 bg-gray-300 w-1/2 rounded"></div>
          <div className="flex  gap-5 items-center mt-3">
            <div className="h-5 bg-gray-300 w-16 rounded"></div>
            <div className="h-5 bg-gray-300 w-16 rounded"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SearchSkeleton;
