import { useState } from "react";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import NavCategorySkeleton from "../../Skeletons/Nav-Category-Skeleton";

const BottomNav = ({ categories, menuRef }) => {
  const [menu, setMenu] = useState("Home");
  const [openDropdown, setOpenDropdown] = useState(null);

  const commonClass =
    "rounded-full text-center border-[1px] border-[#c09f66] text-sm sm:text-lg px-4 py-2 sm:px-3 sm:py-1 transition-all duration-300 w-48 sm:max-w-[170px] hover:bg-[#c09f66] hover:text-white";

  const activeClass = "bg-[#c09f66] text-white hover:bg-[#c09f66]";

  const closeMenu = () => {
    if (menuRef?.current) {
      menuRef?.current?.classList?.remove("nav-menu-visible");
    }
  };

  return (
    <nav
      ref={menuRef}
      className="w-full sm:bg-white mt-[10px] hidden sm:flex flex-col sm:flex-row sm:flex-wrap gap-3 sm:gap-6 justify-center items-center font-medium py-2 sm:p-4"
    >
      <div className="flex flex-col sm:flex-row gap-2 p-2 sm:p-0 sm:gap-6 w-full justify-center items-center">
        <Link
          to="/"
          onClick={() => {
            setMenu("Home");
            closeMenu();
          }}
          className={`${commonClass} ${menu === "Home" ? activeClass : ""}`}
        >
          Home
        </Link>

        {categories && categories.length > 0 ? (
          categories.map((category) => (
            <div
              key={category.id}
              className="relative flex flex-col items-center "
              onMouseEnter={() => setOpenDropdown(category.id)}
              onMouseLeave={() => setOpenDropdown(null)}
            >
              <div
                className={`${commonClass} flex justify-between items-center text-center cursor-pointer hover:bg-[#c09f66]`}
              >
                <span className="sm:ml-0 text-center w-full truncate">
                  {category.name}
                </span>
                {category.subcategories.length > 0 && <FaAngleRight />}
              </div>
              {openDropdown === category.id &&
                category.subcategories.length > 0 && (
                  <div className="absolute left-0 sm:left-24 top-full mt-1 w-56 bg-white shadow-lg rounded-lg p-2 z-50">
                    {category.subcategories.map((subcategory) => (
                      <Link
                        key={subcategory.id}
                        to={`/${category.name.toLowerCase()}/${subcategory.name.toLowerCase()}`}
                        className={`block truncate px-4 py-2 sm:px-3 sm:py-1 rounded-full m-auto max-w-[200px] bg-white  mb-2 hover:bg-[#c09f66] transition-all duration-300 ${commonClass}`}
                        onClick={() => {
                          setMenu(subcategory.name.toLowerCase());
                          setOpenDropdown(null);
                          closeMenu();
                        }}
                      >
                        {subcategory.name.charAt(0).toUpperCase() +
                          subcategory.name.slice(1).toLowerCase()}
                      </Link>
                    ))}
                  </div>
                )}
            </div>
          ))
        ) : (
          // Skeleton Loader when categories are unavailable
          <NavCategorySkeleton />
        )}

        <Link
          to="/blog"
          onClick={() => {
            setMenu("Blog");
            closeMenu();
          }}
          className={`${commonClass} ${menu === "Blog" ? activeClass : ""}`}
        >
          Blog
        </Link>
        <Link
          to="/contact-us"
          onClick={() => {
            setMenu("Wholesale Query");
            closeMenu();
          }}
          className={`${commonClass} ${menu === "Contact" ? activeClass : ""}`}
        >
          Contact
        </Link>
      </div>
    </nav>
  );
};

export default BottomNav;
