import React from "react";
import { Helmet } from "react-helmet";
import {
  CustomerServiceOutlined,
  RightOutlined,
  SafetyCertificateFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Typography } from "antd";
import "./Query.css";
import { Link } from "react-router-dom";

export default function Query() {
  return (
    <div className="query-page-main">
      <Helmet>
        <link rel="canonical" href="https://www.jaipurmasale.com/query" />
      </Helmet>
      <h2 className="query-title">Why Us?</h2>
      <hr />
      <div className="query-description">
        <Row>
          <Col lg={1} xs={2}></Col>
          <Col lg={22} xs={20}>
            <Row>
              <Col lg={11} xs={24}>
                <div>
                  {/* <Typography className="query-section-title">
                    Why Switch to Jaipur Masale & Dry Fruits?
                  </Typography> */}
                  <Typography className="query-text">
                    If you're still reading this, we are pretty sure you already
                    recognize the benefit of buying from us. However, if you are
                    still not convinced, here's a few extra points to change your
                    mind
                  </Typography>
                  <div className="listed-text">
                    <ul>
                      <li>Handmade with Love </li>
                      <li>Preserving Indian Heritage</li>
                      <li>Versatile Product Range</li>
                      <li>Locally Sourced Materials</li>
                      <li>Ethical Fashion & Décor</li>
                      <li>Handcrafted Luxury at Your Doorstep</li>
                      <li>Cultural Storytelling Through Design</li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={4} xs={4}></Col>
              <Col lg={9} xs={22}>
                <div style={{ display: "flex", flexDirection: "column", gap: 25 }}>
                  <div>
                    <Card>
                      <div className="rightside-card">
                        <div className="rightside-icon">
                          <SafetyCertificateFilled
                            style={{ color: "#5e5ee9", fontSize: 26 }}
                          />
                        </div>
                        <div>
                          <Link to="/women/kurtis">
                            <Typography.Title level={3} className="rightside-card-title">
                              Explore Women Apparel
                            </Typography.Title>
                            <Typography className="card-description">
                              A destination for modern and elegant women's fashion.
                            </Typography>
                          </Link>
                        </div>
                        <div className="rightside-secoend-icon">
                          <RightOutlined style={{ fontSize: 26 }} />
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div>
                    <Card>
                      <div className="rightside-card">
                        <div className="rightside-icon">
                          <SafetyCertificateFilled
                            style={{ color: "#5e5ee9", fontSize: 26 }}
                          />
                        </div>
                        <div>
                          <Link to="/accessories/bags">
                            <Typography.Title level={3} className="rightside-card-title">
                              Explore Accessories
                            </Typography.Title>
                            <Typography className="card-description">
                              A stylish collection of accessories to elevate any look.
                            </Typography>
                          </Link>
                        </div>
                        <div className="rightside-secoend-icon">
                          <RightOutlined style={{ fontSize: 26 }} />
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div>
                    <Card>
                      <div className="rightside-card">
                        <div className="rightside-icon">
                          <SafetyCertificateFilled
                            style={{ color: "#5e5ee9", fontSize: 26 }}
                          />
                        </div>
                        <div>
                          <Link to="/home%20&%20living/bunting">
                            <Typography.Title level={3} className="rightside-card-title">
                              Explore Home & Living
                            </Typography.Title>
                            <Typography className="card-description">
                              Creating a cozy and stylish home with curated essentials.
                            </Typography>
                          </Link>
                        </div>
                        <div className="rightside-secoend-icon">
                          <RightOutlined style={{ fontSize: 26 }} />
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={1} xs={2}></Col>
        </Row>
      </div>
    </div>
  );
}
