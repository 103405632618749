import React from "react";
import PopularSkeleton from "./Popular-Skeleton";

const ShopSkeleton = () => {
  return (
    <div className="h-full w-full bg-gray-100 p-4 space-y-6">
      {/* Video Skeleton */}
      <div className="w-full h-[540px] bg-gray-200 animate-pulse rounded-lg"></div>

      {/* New Product Skeletons */}
      <div className="flex flex-wrap justify-center gap-6 md:gap-16 max-w-[1400px] p-20  mx-auto ">
        {[...Array(4)].map((_, index) => (
          <div
            key={index}
            className="w-[150px] h-[150px] sm:w-[200px] sm:h-[200px] lg:w-[250px] lg:h-[250px] bg-gray-300 animate-pulse rounded-full shadow-lg"
          ></div>
        ))}
      </div>

      {/* Popular Section Skeleton */}
      <div className="mt-8 p-2 max-w-[1400px] m-auto ">
        <div className="text-xl font-semibold text-gray-500  m-auto  text-center">
          <p className="w-20 h-4 bg-gray-300 m-auto"></p>
        </div>
        <hr className="my-2 border-gray-300" />
        {/* Product Skeletons */}
        <PopularSkeleton />
      </div>
    </div>
  );
};

export default ShopSkeleton;
