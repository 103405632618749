import React from 'react';

const SingleBlog = () => {
  return (
    <div className="max-w-7xl mx-auto flex flex-col gap-6 p-5 sm:p-6 mt-12 sm:mt-16 animate-pulse">
      {/* Category Skeleton */}
      <div className="h-12 w-32 sm:w-48 bg-gray-200 rounded mx-auto"></div>

      {/* Blog Image Skeleton */}
      <div className="w-full h-64 sm:h-80 bg-gray-200 rounded-lg"></div>

      {/* Title Skeleton */}
      <div className="h-8 w-3/4 bg-gray-300 rounded mx-auto"></div>

      {/* Meta Information Skeleton */}
      <div className="flex gap-4 justify-center mt-4">
        <div className="h-5 w-24 bg-gray-200 rounded"></div>
        <div className="h-5 w-24 bg-gray-200 rounded"></div>
      </div>

      {/* Content Skeleton */}
      <div className="flex flex-col gap-4">
        <div className="h-6 w-full bg-gray-200 rounded"></div>
        <div className="h-6 w-5/6 bg-gray-200 rounded"></div>
        <div className="h-6 w-2/3 bg-gray-200 rounded"></div>
      </div>

      {/* Button Skeleton */}
      <div className="flex justify-center gap-6 mt-6">
        <div className="h-12 w-28 bg-gray-200 rounded"></div>
        <div className="h-12 w-28 bg-gray-200 rounded"></div>
      </div>
    </div>
  );
};

export default SingleBlog;