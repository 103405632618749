// TermsAndConditions.js
import React from "react";
import "./TermsConditionsPolicy.css";

const TermsAndConditions = () => {
  return (
    <div className="terms-conditions-container">
      <h2 className="title">Terms and Conditions</h2>

      <section className="section">
        <h3 className="section-title">Overview</h3>
        <p>
          This website is operated by Kraafty BY Shree Ganesham Handicrafts. Throughout the site, the terms
          “we”, “us” and “our” refer to Kraafty BY Shree Ganesham Handicrafts. NAAV offers this website,
          including all information, tools, and Services available from this site to you, the user, conditioned
          upon your acceptance of all terms, conditions, policies, and notices stated here.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">Online Store Terms</h3>
        <p>
          By agreeing to these Terms of Service, you confirm that you are at least the age of majority in your
          state or province of residence or that you have given us your consent to allow any of your minor
          dependents to use this site.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">General Conditions</h3>
        <p>
          We reserve the right to refuse service to anyone for any reason at any time. You agree not to
          reproduce, duplicate, copy, sell, or exploit any portion of the Service without express written permission
          from us.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">Accuracy, Completeness, and Timeliness of Information</h3>
        <p>
          We are not responsible if information available on this site is not accurate, complete, or current.
          The material on this site is for general information purposes only.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">Modifications to the Service and Prices</h3>
        <p>
          Prices for our products are subject to change without notice. We reserve the right at any time to modify
          or discontinue the Service without notice.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">Products or Services</h3>
        <p>
          Certain products or services may be available exclusively online through the website. These products
          may have limited quantities and are subject to return or exchange only according to our Refund Policy.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">Accuracy of Billing and Account Information</h3>
        <p>
          We reserve the right to refuse any order you place with us. You agree to provide current, complete, and
          accurate purchase and account information for all transactions.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">Optional Tools</h3>
        <p>
          We may provide you with access to third-party tools over which we neither monitor nor have any control.
          You acknowledge and agree that we provide access to such tools "as is" without any warranties.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">Personal Information</h3>
        <p>
          Your submission of personal information through the store is governed by our Privacy Policy.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">Prohibited Uses</h3>
        <p>
          You are prohibited from using the site or its content for any unlawful purpose, to solicit others to perform
          unlawful acts, to violate any laws, or to infringe upon our intellectual property rights.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">Disclaimer of Warranties; Limitation of Liability</h3>
        <p>
          We do not guarantee that your use of our Service will be uninterrupted, timely, secure, or error-free.
          The Service and all products delivered are provided 'as is' without any warranty.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">Indemnification</h3>
        <p>
          You agree to indemnify, defend, and hold harmless Kraafty BY Shree Ganesham Handicrafts from any
          claim or demand made by any third-party due to your breach of these Terms of Service.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">Severability</h3>
        <p>
          If any provision of these Terms of Service is determined to be unlawful or unenforceable, such provision
          shall be severed, and the remaining provisions shall remain in full force and effect.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">Termination</h3>
        <p>
          These Terms of Service are effective unless and until terminated by either you or us. We reserve the right
          to terminate your access if you fail to comply with any of these terms.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">Governing Law</h3>
        <p>
          These Terms of Service shall be governed by and construed in accordance with the laws of India.
        </p>
      </section>

      <section className="section">
        <h3 className="section-title">Changes to Terms of Service</h3>
        <p>
          We reserve the right to update, change, or replace any part of these Terms of Service at our sole
          discretion. It is your responsibility to check our website periodically for updates.
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
