import React from "react";

const ScrollerSkeleton = () => {
  return (
    <div className="header-scroller bg-gray-100 animate-pulse p-2  w-full h-12">
      <div className="h-8 bg-gray-200 rounded w-2/5 mx-auto"></div>
    </div>
  );
};

export default ScrollerSkeleton;
