import "./App.css";
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Shop from './Pages/Shop';
import ShopCategory from './Pages/ShopCategory';
import Product from './Pages/Product';
import Cart from './Pages/Cart';
import Signup from './Pages/Signup';
import Login from './Components/Login/Login';
import BlogDetail from './Pages/Blog/BlogDetail';
import BlogPage from './Pages/Blog/BlogPage';
import AddItems from './Pages/AddItems';
import ScrollingUp from './Components/ScrollingUp/ScrollingUp';
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import ContactUs from "./Components/ContactUs/ContactUs";
import Query from "./Components/Query/Query";
import AboutUs from "./Components/AboutUs/AboutUs";
import CustomerReviews from "./Components/CustomerReviews/CustomerReviews.jsx";
import WhatsAppButton from './Components/WhatsAppButton/WhatsAppButton';
import Footer2 from "./Components/Footer2/Footer2.jsx";
import SearchResults from "./Components/SearchResults/SearchResults.jsx";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy.jsx";
import RefundPolicy from "./Components/RefundPolicy/RefundPolicy.jsx";
import ShippingPolicy from "./Components/ShippingPolicy/ShippingPolicy.jsx";
import TermsConditionsPolicy from "./Components/TermsConditionsPolicy/TermsConditionsPolicy.jsx";
import NotFound from "./Components/NotFound/NotFound";
import { Suspense, lazy } from 'react';
import ScrollerSkeleton from '../src/Skeletons/Scroller-Skeleton.jsx'
// Sample banner map for categories
import khada_masala_banner from './Components/Assets/banner_women.webp';

// Lazy loading components
const Scroller = lazy(() => import('./Components/Scroller/Scroller'));

const bannerMap = {
  women: khada_masala_banner,
  home_decor: khada_masala_banner,
  garden_decor: khada_masala_banner
};

const categoryMap = {
  women: 1,
  "home & living": 2,
  men: 3,
  kids: 4,
  fabric: 5,
  accessories: 6,
};

const subCategoryMap = {
  kurtis: 1,
  lehengas: 2,
  pants: 3,
  "crop tops": 4,
  "harem pants": 5,
  "pj set": 6,
  "kaftan robes": 7,
  sarong: 8,
  napkins: 9,
  bunting: 10,
  "door valence": 11,
  ottoman: 12,
  "table runner": 13,
  umbrellas: 14,
  curtains: 15,
  "cushion cover": 16,
  "wall decor": 17,
  bags: 18,
  "pajama set": 19,
  jacket: 20,
  toiletry: 21,
  "rugs & carpets": 22,
};

const DynamicShopCategory = () => {
  const { pathname } = useLocation();
  const decodedPath = decodeURIComponent(pathname); // Decodes %20 and other encoded characters
  const parts = decodedPath.split('/').filter(Boolean);
  const categorySlug = parts[0];
  const subCategorySlug = parts[1] || null;

  const category_id = categoryMap[categorySlug] || null;
  const sub_category_id = subCategorySlug ? subCategoryMap[subCategorySlug] : null;
  const banner = bannerMap[categorySlug] || khada_masala_banner;

  if (!category_id) return <NotFound />;

  return (
    <ShopCategory
      banner={banner}
      category_id={category_id}
      sub_category_id={sub_category_id}
    />
  );
};

const Layout = ({ children }) => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <>
      {isHomePage && (
        <Suspense fallback={<ScrollerSkeleton />}>
          <Scroller />
        </Suspense>
      )}
      <Navbar isHomePage={isHomePage} />
      {children}
    </>
  );
};

function App() {
  return (
    <div>
      <BrowserRouter>
        <Layout>
          <ScrollingUp />
          <Routes>
            <Route path="/" element={<Shop />} />
            <Route path="/:category" element={<DynamicShopCategory />} />
            <Route path="/:category/:subCategory" element={<DynamicShopCategory />} />

            <Route path="/add_items" element={<AddItems />} />
            <Route path="/product/:productId" element={<Product />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/query" element={<Query />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/customer-reviews" element={<CustomerReviews />} />
            <Route path="/blog/:id" element={<BlogDetail />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/Signup" element={<Signup />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/search" element={<SearchResults />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/refund" element={<RefundPolicy />} />
            <Route path="/shipping" element={<ShippingPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsConditionsPolicy />} />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer2 />
          <WhatsAppButton />
        </Layout>
        <Analytics />
        <SpeedInsights />
      </BrowserRouter>
    </div>
  );
}

export default App;
