import React, { lazy, Suspense } from "react"; // Lazy loading for components
import { Helmet } from "react-helmet";
import ShopSkeleton from "../Skeletons/Shop-Skeleton.jsx";

// Lazy-loaded Components
const Hero = lazy(() => import("../Components/Hero/Hero"));
const Popular = lazy(() => import("../Components/Popular/Popular"));
const NewCollections = lazy(() =>
  import("../Components/NewCollections/NewCollections")
);
const CustomerReviews = lazy(() =>
  import("../Components/CustomerReviews/CustomerReviews.jsx")
);
const AboutUs = lazy(() => import("../Components/AboutUs/AboutUs"));
const ContactUs = lazy(() => import("../Components/ContactUs/ContactUs"));
// const CountDown = lazy(() => import('../Components/CountDown/CountDown'));
const Faq = lazy(() => import("../Components/FAQ/Faq.jsx"));
const Instagram = lazy(() => import("../Components/Instagram/Instagram"));
const NewsLetter = lazy(() => import("../Components/NewsLetter/NewsLetter"));
const ProductGallery = lazy(() =>
  import("../Components/ProductGallery/ProductGallery")
);
const Query = lazy(() => import("../Components/Query/Query"));

const Shop = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "kraafty",
    url: "https://www.kraafty.com/",
    description: "Explore the best quality clothes from kraafty.",
    publisher: {
      "@type": "Organization",
      name: "kraafty",
      logo: {
        "@type": "ImageObject",
        url: "https://www.kraafty.com/static/media/kraafty.7b4c1691ff1155ba4061.png",
      },
    },
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.kraafty.com",
    },
  };

  const organizationData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "kraafty",
    url: "https://www.kraafty.com",
    logo: "https://www.kraafty.com/static/media/kraafty.7b4c1691ff1155ba4061.png",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+91 9468599356",
      contactType: "Customer Service",
    },
    sameAs: [
      "https://www.facebook.com/jaipurmasale",
      "https://www.instagram.com/jaipurmasale",
    ],
  };

  return (
    <div>
      <Helmet>
        <title>Kraafty | Explore the best quality clothes from kraafty.</title>
        <meta
          name="description"
          content="Discover the best quality natural authentic Indian spices and premium dry fruits from Jaipur Masale. Shop the best flavors today! Online Masala Free Delivery"
        />
        {/* Structured Data for WebPage */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
        {/* Structured Data for Organization */}
        <script type="application/ld+json">
          {JSON.stringify(organizationData)}
        </script>
      </Helmet>

      <Suspense fallback={<ShopSkeleton />}>
        <Hero />
        <ProductGallery />
        <Popular />
        <NewCollections />

        {/* Prioritize Above-the-Fold Content */}
        <Query />
        {/* Consider lazy loading ProductGallery if it's below the fold */}
        {/* <Suspense fallback={<div>Loading Gallery...</div>}>
          <ProductGallery />
        </Suspense> */}

        {/* Lazy Load Less Important Components */}
        <Suspense fallback={<div>Loading Instagram Feed...</div>}>
          <Instagram />
        </Suspense>

        <Suspense fallback={<div>Loading Customer Reviews...</div>}>
          <CustomerReviews />
        </Suspense>
        <Suspense fallback={null}>
          <AboutUs />
        </Suspense>
        <Suspense fallback={null}>
          {" "}
          {/* No need for a fallback for Newsletter */}
          <NewsLetter />
        </Suspense>
        <Suspense fallback={null}>
          {" "}
          {/* No need for a fallback for About Us */}
          <ContactUs />
        </Suspense>
        <Suspense fallback={null}>
          {" "}
          {/* No need for a fallback for About Us */}
          <Faq />
        </Suspense>
        <Suspense fallback={null}>
          {" "}
          {/* No need for a fallback for About Us */}
          {/* <CountDown /> */}
        </Suspense>
      </Suspense>
    </div>
  );
};

export default Shop;
