import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import './CSS/ShopCategory.css';
import axios from 'axios';
import ProductsListSkeleton from '../Skeletons/Products-List-Skeleton'

// import Item from '../Components/Item/Item';
// import dropdown_icon from '../Components/Assets/dropdown_icon.webp';
// Lazy load components
const Item = lazy(() => import('../Components/Item/Item'));
const ContactUs = lazy(() => import('../Components/ContactUs/ContactUs'));
const ShopCategory = ({ banner, category_id, sub_category_id }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategoryProducts = async () => {
      setLoading(true); // Set loading to true when fetching starts
      try {
        const response = await axios.get(
          `https://kraafty-backend.onrender.com/api/get-all-products/`,
          {
            params: {
              category_pk: category_id,
              sub_category_pk: sub_category_id, // Include sub-category parameter
            },
          }
        );
        setProducts(response.data.data);
        setError(null); // Clear any previous error
      } catch (error) {
        console.error('Error fetching category products:', error);
        setError('Failed to load products. Please try again later.');
      } finally {
        setLoading(false); // Set loading to false after fetching completes
      }
    };

    fetchCategoryProducts();
  }, [category_id, sub_category_id]); // Dependency updated to include sub_category_id


  const schemaData = {
    "@context": "https://schema.org",
    "@type": "ProductCollection",
    "name": "Shop Category",
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "itemListElement": products.map((product) => ({
        "@type": "Product",
        "name": product.product_name,
        "image": product.main_image,
        "description": product.description || "Quality spices and products from Jaipur Masale",
        "offers": {
          "@type": "Offer",
          "priceCurrency": "USD",
          "price": product.offer_price,
          "itemCondition": "https://schema.org/NewCondition",
          "availability": product.is_active ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
          "url": `https://www.jaipurmasale.com/product/${product.id}`
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": product.rating,
          "reviewCount": product.review_count || 0
        }
      }))
    }
  };

  if (loading) {
    return <ProductsListSkeleton />; // Show loading state
  }

  if (error) {
    return <div className="error-message">{error}</div>; // Show error message
  }

  return (
    <div className='shop-category'>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
      </Helmet>
      <img className='shopcategory-banner' src={banner} alt="" />
      <div className="shopcategory-indexSort">
        <p>
          <span>Showing 1-{Math.min(12, products.length)}</span> out of {products.length} products
        </p>
        {/* <div className="shopcategory-sort">
          Sort by <img src={dropdown_icon} alt="" />
        </div> */}
      </div>
      <div className="shopcategory-products">
        <Suspense fallback={<ProductsListSkeleton />}> {/* Add Suspense */}
          {products
            .filter(item => item.is_active) // Only show active products
            .map((item) => (
              <Item
                key={item.id}
                id={item.id}
                name={item.product_name}
                image={item.main_image}
                new_price={item.offer_price}
                old_price={item.mrp}
                rating={item.rating}
              />
            ))}
        </Suspense>
      </div>
      <div className="shopcategory-loadmore" onClick={() => console.log('Load more products')}>
        Explore More
      </div>
      <ContactUs />
    </div>
  );
};

export default ShopCategory;
