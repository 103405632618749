import React, { useState } from "react";
import "./CSS/Signup.css";
import logo from "../Components/Assets/logo1.webp";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    mobileNumber: "",
    password: "",
    agree: false,
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "Please fill up this field";
    if (!formData.lastName) newErrors.lastName = "Please fill up this field";
    if (!formData.email) newErrors.email = "Please fill up this field";
    if (!formData.countryCode)
      newErrors.countryCode = "Please fill up this field";
    if (!formData.mobileNumber)
      newErrors.mobileNumber = "Please fill up this field";
    if (!formData.agree) newErrors.agree = "You must agree to the terms";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const response = await axios.post(
          "https://kraafty-backend.onrender.com/api/signup/",
          {
            email: formData.email,
            mobile_number: formData.mobileNumber,
            country_code: formData.countryCode,
            first_name: formData.firstName,
            last_name: formData.lastName,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Signup successful", response.data);
        navigate("/login");

      } catch (error) {
        console.error("Signup failed", error);
      }
    }
  };

  return (
    <div className="loginsignup">
      <div className="loginsignup-container">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <h1>Sign Up</h1>
        <form onSubmit={handleSubmit}>
          <div className="loginsignup-fields">
            <div className="loginsignup-row">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
                className={errors.firstName && "error"}
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                className={errors.lastName && "error"}
              />
            </div>
            {errors.firstName && (
              <span className="error-msg">{errors.firstName}</span>
            )}
            {errors.lastName && (
              <span className="error-msg">{errors.lastName}</span>
            )}

            <input
              type="email"
              name="email"
              placeholder="Email Address"
              value={formData.email}
              onChange={handleChange}
              className={errors.email && "error"}
            />
            {errors.email && <span className="error-msg">{errors.email}</span>}

            <div className="loginsignup-row">
              <input
                type="text"
                name="countryCode"
                placeholder="Country Code"
                value={formData.countryCode}
                onChange={handleChange}
                className={errors.countryCode && "error"}
              />
              <input
                type="text"
                name="mobileNumber"
                placeholder="Mobile Number"
                value={formData.mobileNumber}
                onChange={handleChange}
                className={errors.mobileNumber && "error"}
              />
            </div>
            {errors.countryCode && (
              <span className="error-msg">{errors.countryCode}</span>
            )}
            {errors.mobileNumber && (
              <span className="error-msg">{errors.mobileNumber}</span>
            )}

            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              className={errors.password && "error"}
            />
            {errors.password && (
              <span className="error-msg">{errors.password}</span>
            )}

            <div className="loginsignup-agree">
              <input
                type="checkbox"
                name="agree"
                checked={formData.agree}
                onChange={handleChange}
                className={`custom-checkbox ${errors.agree ? "error" : ""}`}
              />
              <p>
                By continuing, I agree to the terms of use & privacy policy.
              </p>
            </div>
            {errors.agree && <span className="error-msg">{errors.agree}</span>}
          </div>
          {errors.apiError && (
            <span className="error-msg">{errors.apiError}</span>
          )}
          <button type="submit">Continue</button>
        </form>
        <p className="loginsignup-login">
          Already have an account?{" "}
          <Link to="/login">
            <span>Login here</span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Signup;
