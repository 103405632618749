const BlogsSkeleton = () => {
  return (
    <div className="max-w-7xl mx-auto flex flex-col gap-6 p-5 sm:p-6 mt-10 sm:mt-14">
      {/* Title Skeleton */}
      <div className="h-10 mx-auto bg-gray-200 w-32 sm:w-48 rounded"></div>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            className="flex flex-col p-6 rounded-lg border-2 shadow-md animate-pulse bg-white w-full min-h-[320px] sm:min-h-[420px]"
          >
            {/* Image Skeleton */}
            <div className="w-full h-48 bg-gray-200 rounded"></div>

            {/* Title Skeleton */}
            <div className="mt-4 h-6 bg-gray-300 w-3/4 rounded"></div>
            
            {/* Description Skeleton */}
            <div className="mt-3 h-4 bg-gray-200 w-full rounded"></div>
            <div className="mt-2 h-4 bg-gray-200 w-5/6 rounded"></div>
            <div className="mt-2 h-4 bg-gray-200 w-3/4 rounded"></div>

            {/* Buttons Skeleton */}
            <div className="flex gap-4 mt-5">
              <div className="h-10 bg-gray-200 w-28 rounded"></div>
              <div className="h-10 bg-gray-200 w-28 rounded"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogsSkeleton;
