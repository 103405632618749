import React from "react";

const PopularSkeleton = () => {
  return (
    <div className="w-full p-4">
      <div className="best_seller text-xl font-bold mb-4 animate-pulse bg-gray-300 h-6 w-48 rounded m-auto"></div>
      <hr className="mb-4 m-auto text-gray-300" />
      <div className="flex flex-wrap justify-between gap-4  p-2">
        {[...Array(8)].map((_, index) => (
          <div
            key={index}
            className="flex flex-col p-4 justify-between w-64 h-[400px]  rounded-lg border-2  shadow-md animate-pulse bg-white"
          >
            <div className="w-full h-40 bg-gray-400 rounded"></div>
            <div className="mt-3 h-4 bg-gray-300 w-3/4 rounded"></div>
            <div className="mt-2 h-4 bg-gray-300 w-1/2 rounded"></div>
            <div className="flex  gap-5 items-center mt-3">
              <div className="h-5 bg-gray-300 w-16 rounded"></div>
              <div className="h-5 bg-gray-300 w-16 rounded"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopularSkeleton;
