import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import Marquee from "react-light-marquee";
import { useEffect, useState } from "react";
import reviews from "./reviews.json";
import ditto from "./ditto.webp";

// Generate star rating UI
const renderStars = (rating) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(<FaStar key={i} className="text-yellow-500" />);
    } else if (i - 0.5 === rating) {
      stars.push(<FaStarHalfAlt key={i} className="text-yellow-500" />);
    } else {
      stars.push(<FaRegStar key={i} className="text-yellow-500" />);
    }
  }
  return stars;
};

// Single review card
const Card = ({ name, rating, message }) => {
  return (
    <div className="p-2 sm:p-3 hover:shadow-xl hover:border-gray-200 hover:border-2 border-2 bg-white text-white rounded-xl border-gray-100 max-w-xs cursor-pointer">
      <div className="flex flex-col sm:flex-row items-center justify-between">
        <h3 className="font-semibold text-sm sm:text-lg text-gray-800">
          {name}
        </h3>
        <div className="flex">{renderStars(rating)}</div>
      </div>
      <p className="text-sm text-gray-700 mt-1 sm:mt-2">{message}</p>
    </div>
  );
};

const CustomerReviews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);

  // Auto-change testimonials every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentIndex((prev) => (prev + 1) % reviews.length);
        setFade(true);
      }, 300); // Smooth fade transition
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="sm:px-20 md:px-20 py-5 sm:py-4">
      <div className="shopby text-center flex flex-col justify-center items-center px-4 sm:px-2">
        <h2 className="shopby-title text-xl sm:text-2xl">
          Inspiring Change: Hear from Our Customers!
        </h2>
        <hr />
      </div>

      <div className="relative w-full max-h-[600px] flex flex-col md:flex-row items-center justify-around">
        <div className="absolute top-0 left-0 w-full h-10 bg-gradient-to-b from-gray-100 to-transparent z-10"></div>

        {/* Left Section - Auto-changing Testimonials */}
        <div className="w-full md:w-1/2 h-1/2 sm:h-full p-4 px-6 sm:px-2  m-1 sm:m-1 md:p-8 md:m-4 text-center md:text-left relative flex flex-col gap-1 sm:gap-4">
          <h2 className="text-sm sm:text-lg text-gray-500 mb-4">
            What people think about us
          </h2>
          <div
            className={`text-lg text-gray-700 min-h-[120px] transition-opacity duration-500 ${
              fade ? "opacity-100" : "opacity-0"
            }`}
          >
            <h3 className="font-semibold text-xl text-gray-900">
              {reviews[currentIndex].name}
            </h3>
            <div className="flex justify-center md:justify-start mb-2">
              {renderStars(reviews[currentIndex].rating)}
            </div>
            <p className="text-gray-700">{reviews[currentIndex].message}</p>
          </div>
          {/* Dots for Changing Testimonials */}
          <div className="flex justify-center mt-4">
            {reviews.map((_, index) => (
              <button
                key={index}
                className={`h-3 w-3 mx-1 rounded-full transition-all duration-300 ${
                  currentIndex === index ? "bg-gray-800" : "bg-gray-400"
                }`}
                onClick={() => setCurrentIndex(index)}
              ></button>
            ))}
          </div>
        </div>

        {/* Right Section (Marquees) */}
        <div className="w-full md:w-1/2 h-full max-h-[600px] px-5 sm:px-10 flex flex-row md:flex-row justify-around gap-0 sm:gap-6 overflow-hidden">
          {/* First Marquee */}
          <Marquee direction="up" pauseOnHover speed={30}>
            {reviews.map((review, index) => (
              <Card
                key={index}
                name={review.name}
                rating={review.rating}
                message={review.message}
              />
            ))}
          </Marquee>

          {/* Second Marquee */}
          <Marquee direction="up" pauseOnHover speed={30}>
            {reviews
              .slice()
              .reverse()
              .map((review, index) => (
                <Card
                  key={index}
                  name={review.name}
                  rating={review.rating}
                  message={review.message}
                />
              ))}
          </Marquee>

          {/* Ditto Image */}
          <img
            src={ditto}
            alt="ditto_image"
            height={200}
            width={100}
            className="hidden sm:flex absolute -right-12 bottom-64 sm:w-28 sm:h-28 mt-10"
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerReviews;
