import React from "react";

const SingleProductSkeleton = () => {
  return (
    <div className="p-4 sm:p-5 md:p-10 max-w-[1280px] m-auto animate-pulse">
      {/* Back Button */}
      <div className="h-6 w-32 sm:w-44 bg-gray-300 rounded-md mb-4"></div>

      <div className="flex flex-col md:flex-row gap-6 w-full h-full">
        {/* Left Side - Images */}
        <div className="flex flex-row md:flex-col gap-4 justify-center md:justify-start">
          {[...Array(5)].map((_, index) => (
            <div key={index} className="w-16 h-16 bg-gray-300 rounded-md"></div>
          ))}
        </div>

        {/* Preview Image */}
        <div className="w-full h-64 sm:h-[500px] sm:w-[500px] md:w-[600px] md:h-[600px] bg-gray-300 rounded-lg"></div>

        {/* Right Side - Product Details */}
        <div className="space-y-4 flex-1 flex flex-col justify-between">
          {/* Product Title */}
          <div className="h-8 sm:h-16 md:h-20 bg-gray-300 rounded-md"></div>
          {/* Rating */}
          <div className="h-4 w-20 bg-gray-300 rounded-md"></div>
          {/* Price */}
          <div className="h-6 w-1/3 bg-gray-300 rounded-md"></div>
          <div className="h-10 sm:h-16 md:h-20 bg-gray-300 rounded-md flex flex-col justify-around px-3">
            <div className="h-6 bg-gray-200 rounded-md"></div>
            <div className="h-6 bg-gray-200 rounded-md"></div>
          </div>

          {/* Quantity Selector */}
          <div className="flex flex-wrap gap-4">
            <div className="h-10 w-20 bg-gray-300 rounded-md"></div>
            <div className="h-10 w-20 bg-gray-300 rounded-md"></div>
            <div className="h-10 w-20 bg-gray-300 rounded-md"></div>
          </div>
          <div className="h-4 w-20 bg-gray-300 rounded-md"></div>
          {/* Price */}
          <div className="h-6 w-2/3 bg-gray-300 rounded-md"></div>
          {/* Buttons */}
          <div className="flex flex-col sm:flex-row gap-4 mt-4">
            <div className="h-12 w-full sm:w-56 bg-gray-300 rounded-sm"></div>
            <div className="h-12 w-full sm:w-56 bg-gray-300 rounded-sm"></div>
          </div>
        </div>
      </div>

      {/* Bottom Section - Description */}
      <div className="mt-8 space-y-4">
        {/* Description */}
        <div className="h-6 w-40 bg-gray-300 rounded-md"></div>
        <div className="h-4 w-full bg-gray-300 rounded-md"></div>
        <div className="h-4 w-3/4 bg-gray-300 rounded-md"></div>
      </div>
    </div>
  );
};

export default SingleProductSkeleton;
