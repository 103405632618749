import React from "react";
import { Helmet } from "react-helmet";
import "./AboutUs.css";
import Image from "../Assets/mother.jpg";

const AboutUs = () => {
  return (
    <div className="about">
      <Helmet>
        <link rel="canonical" href="https://jaipurmasale.com/about-us" />
      </Helmet>
      <h2 className="about-title">A Legacy of Love and Design</h2>
      <hr/>
      <div className="about-container">
        <div className="about-content">
          <p className="about-text">
            Founded in June 2018 in Jaipur, Rajasthan, <strong>Kraafty </strong>began as a passion project to showcase Indian craftsmanship. Today, we are a globally recognized brand, offering ethically produced, <strong>handcrafted home décor, textiles, and garments</strong> while supporting artisans with fair wages and ethical work conditions.
          </p>
          <br />
          {/* <p className="about-text">
              <strong>Mother's Note:</strong>
          </p> */}
          {/* <p className="about-text">
            "ये व्यंजन और मसाले ख़ास रूप से मेरे घर और हमारे भारतीय संस्कृति और व्यंजन को याद करते हुए बनाए गए हैं। मेरी आशा है कि हम इस विरासत को आगे की पीढ़ी को संजोएं, परिवार के साथ भोजन का आनंद लें और टेबल पर समर्थ, स्वस्थ भोजन की संस्कृति बनाएं।" - बसंती देवी
          </p> */}
          <p className="about-signature">
            Sincerely,
            <br />
            {/* <strong>Garv Tambi</strong>
            <br /> */}
            Kraafty
          </p>
        </div>
        <div className="about-image">
          <img
            src={Image}
            alt="About Jaipur Masale & Dry Fruits"
            width="600"  // Add explicit width
            height="400" // Add explicit height
            loading="lazy" // Lazy load the image
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
