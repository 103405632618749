import React, { useContext, useRef, useState, useEffect } from "react";
import "./Navbar.css";
import cart_icon from "../Assets/cart_icon.png";
import nav_dropdown from "../Assets/nav_dropdown.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import { ShopContext } from "../../Context/ShopContext";
import { useNavigate } from "react-router";
import kraafty from "../Assets/kraafty.png";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import BottomNav from "./Bottom-Nav";

const Navbar = ({ isHomePage }) => {
  const [menu, setMenu] = useState("shop");
  const { clearCartItems, cartItems, getTotalCartItems } =
    useContext(ShopContext);
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("token")
  );
  // const [scrollUp, setScrollUp] = useState(true);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const menuRef = useRef();
  const imageRef = useRef();
  const Navigate = useNavigate();
  const lastScrollY = useRef(window.scrollY);

  // Fetch all categories  which has subcategories!
  useEffect(() => {
    const fetchCategoriesWithSubcategories = async () => {
      try {
        const response = await axios.get(
          "https://kraafty-backend.onrender.com/api/get-all-category-list/"
        );

        if (response.data && response.data.categories) {
          const filteredCategories = response.data.categories.filter(
            (category) =>
              category.subcategories && category.subcategories.length > 0
          );

          setCategories(filteredCategories);
        }
      } catch (error) {
        console.error("Failed to fetch categories with subcategories:", error);
      }
    };

    // Call the function
    fetchCategoriesWithSubcategories();
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Perform the search
  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    try {
      Navigate(`/search?query=${searchQuery}`);
    } catch (error) {
      console.error("Search error:", error);
    }
  };

  const dropdownToggle = (e) => {
    menuRef?.current?.classList?.toggle("nav-menu-visible");
    e?.target?.classList?.toggle("open");
  };

  // Listen for token changes in localStorage to detect login/logout
  useEffect(() => {
    const handleStorageChange = () => {
      const token = localStorage.getItem("token");
      setIsAuthenticated(!!token);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // Recalculate cart count whenever cartItems changes
  useEffect(() => {
    getTotalCartItems();
  }, [cartItems]);

  // Logout function
  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    clearCartItems();
    Navigate("/");
  };

  // Scroll listener to hide/show Navbar based on scroll direction
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      // setScrollUp(currentScrollY < lastScrollY.current || currentScrollY <= 100);
      lastScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className={`navbar visible' ${isHomePage ? "home" : "not-home"}`}>
        {/* nav with logo */}
        <div className="navbar-horizontal justify-end">
          <Link to="/" onClick={() => setMenu("shop")} className="nav-logo ">
            <img src={kraafty} alt="Logo" />
          </Link>

          <div className="nav-login-cart flex flex-row justify-end  items-center gap-7 max-w-[220px] w-full  sm:max-w-full">
            <form
              onSubmit={handleSearchSubmit}
              className="nav-search-form-desktop  w-9/12 border-[#c09f66] border-[1px]"
            >
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
                className="outline-none  text-lg px-3 py-2 rounded-lg min-h-9 w-full"
              />
              <button type="submit" className="nav-search-button p-2 ">
                <FaSearch size={20} />
              </button>
            </form>

            {!isAuthenticated ? (
              <Link to="/Login">
                <button className="w-28 h-10 outline-none border border-[#c09f66]  rounded-full text-[#515151] text-lg font-medium bg-white cursor-pointer transition-all duration-300 ease-in-out hover:bg-gradient-to-r hover:from-[#76c329] hover:via-[#b1256b] hover:to-[#c0056c] hover:text-white hover:border-transparent sm:w-32 sm:h-12 sm:text-xl">
                  Login
                </button>
              </Link>
            ) : (
              <div className="profile-dropdown">
                <FontAwesomeIcon
                  icon={faUser}
                  style={{ color: "#000", fontSize: "24px" }}
                />
                <div className="dropdown-content">
                  <button onClick={handleLogout}>Logout</button>
                </div>
              </div>
            )}
            <Link to="/cart">
              <img src={cart_icon} alt="Cart" />
            </Link>
            <div className="nav-cart-count">{getTotalCartItems()}</div>

            <img
              onClick={dropdownToggle}
              className="nav-dropdown"
              src={nav_dropdown}
              alt="Menu"
              ref={imageRef}
            />
          </div>
        </div>

        <form onSubmit={handleSearchSubmit} className="nav-search-form">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="nav-search-input"
          />
          <button type="submit" className="nav-search-button  p-2 m-1">
            <FaSearch />
          </button>
        </form>
      </div>

      {/* Bottom Nav for navlinks */}
      <BottomNav categories={categories} menuRef={menuRef} />
    </>
  );
};

export default Navbar;
