// import React from "react";
// import { Link } from "react-router-dom";
// import blogs from "./blogsData";
// import "./CSS/BlogPage.css";

// const BlogPage = () => {
//   return (
//     <div className="blog-container">
//       {blogs.map((blog) => (
//         <div key={blog.id} className="blog-card">
//           <div className="blog-image-wrapper">
//             <img src={blog.image} alt={blog.title} className="blog-image" />
//           </div>
//           <div className="blog-content">
//             <h2 className="blog-title">{blog.title}</h2>

//             {/* <p className="blog-description">{blog.description}</p> */}
//             <div className="link-box">
//               <Link to={`/blog/${blog.id}`} className="blog-read-more">
//                 Read More...
//               </Link>

//               <a
//                 href={blog.product_link}
//                 className="blog-product-link"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 Buy
//               </a>
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default BlogPage;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "./CSS/BlogPage.css";
import BlogsSkeleton from "../../Skeletons/Blogs-Skeleton";
const placeholderImage =
  "https://imgs.search.brave.com/Tn5pKlMOw0_FerWffTprFCqRcdROmyyfH62WlFnrX-A/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly91cGxv/YWQud2lraW1lZGlh/Lm9yZy93aWtpcGVk/aWEvY29tbW9ucy9h/L2FjL05vX2ltYWdl/X2F2YWlsYWJsZS5z/dmc";

const BlogPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    const fetchBlogs = async () => {
      try {
        const response = await fetch(
          "https://kraafty-backend.onrender.com/api/get-all-blogs/"
        );
        const data = await response.json();
        setBlogs(data.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  // Generate JSON-LD structured data for all blog posts
  const blogSchemaData = blogs.map((blog) => ({
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: blog.title,
    image: blog.image,
    author: {
      "@type": "Person",
      name: "Jaipur Masale",
    },
    publisher: {
      "@type": "Organization",
      name: "Jaipur Masale",
      logo: {
        "@type": "ImageObject",
        url: "https://www.jaipurmasale.com/logo.webp", // Adjust to your logo URL
      },
    },
    datePublished: blog.date, // Assuming `blog.date` holds publish date
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `https://www.jaipurmasale.com/blog/${blog.id}`,
    },
    description:
      blog.description ||
      "Discover the latest updates and recipes from Jaipur Masale",
  }));

  if (loading) return <BlogsSkeleton />;

  return (
    <div className="blog-container">
      <Helmet>
        <link rel="canonical" href="https://www.jaipurmasale.com/Blog" />
        {/* Add JSON-LD script with structured data for each blog */}
        <script type="application/ld+json">
          {JSON.stringify(blogSchemaData)}
        </script>
      </Helmet>
      {blogs.map((blog) => (
        <Link to={`/blog/${blog.id}`} className="blog-card-box">
          <div key={blog.id} className="blog-card">
            <div className="blog-image-wrapper">
              <img
                src={blog.image || placeholderImage}
                alt={blog.title}
                className="blog-image w-full h-40 sm:h-60 object-contain"
                loading="lazy"
                onError={(e) => (e.target.src = placeholderImage)}
              />
            </div>
            <div className="blog-content">
              <h2 className="blog-title">{blog.title}</h2>

              {/* Footer */}
              <div className="flex justify-between items-center mt-4">
                <Link
                  to={`/blog/${blog.id}`}
                  className="text-blue-500 hover:underline"
                >
                  Read More
                </Link>
                {blog.product_link && (
                  <a
                    href={blog.product_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                  >
                    Buy
                  </a>
                )}
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default BlogPage;
